import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import FeatureColumn from "../components/Content/FeatureColumn/Index";

// assets
import brandConex from "../assets/logos/logo.svg";

// data
import featuredBrands from "../pages/static-pages/content/where-to-buy.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Where to buy"
        description="Conex Bänninger products are available through Reece, Actrol and Metalflex stores. With over 600+ locations Australia-wide, they’re available near you."
      />
      <BannerBasic title="Where To Buy" />
      <LeadIn
        brandMediaUrl={brandConex}
        brandMediaTitle="Conex Bänninger Logo"
        brandMediaWidth="306px"
        summary="Conex Bänninger products are available through Reece, Actrol and Metalflex stores. With over 600 locations Australia-wide, they’re available near you."
      />
      <FeatureColumn grid="medium" columns="3" data={featuredBrands.items} />
    </Layout>
  );
};

export default IndexPage;
